/* eslint-disable jsx-a11y/heading-has-content */
import React from "react";

const ServicesSection = () => {
  return (
    <section className="lj tp kr" style={{ marginTop: 200 }}>

      {/* Section Title Start */}
      <div
        x-data={{
          sectionTitle: "We Offer The Best Quality Service for You",
          sectionTitleText:
            "",
        }}
      >
        <div className="animate_top bb ze rj ki xn vq">
          <h2
            x-text="sectionTitle"
            className="fk vj pr kk wm on/5 gq/2 bb _b"
          >Are you eager to know more about your tenants?</h2>
          <p className="bb on/5 wo/5 hq" x-text="sectionTitleText">
          Your Trusted and Reliable Partner for Real Estate Data and Property Management in
Nigeria.</p>

        </div>
      </div>
      {/* Section Title End */}

      <div className="bb ze ki xn yq mb en">
        <div className="wc qf" style={{lineHeight: "2.5"}}>
          {/* Service Items */}
          <a href="https://hrmaneja.com/" target="_blank" rel="noreferrer" >
            <div
              className="animate_top sg oi pi zq ml il am cn"
              style={{ cursor: "pointer" }}
            >
              <img
                width="300px"
                // height="50px"
                src="images/landlordimage.jpg"
                alt="Icon"
              />
              <h2 className="ek zj kk wm nb _b">For Landlords</h2>
              <h5>1. Create Properties.</h5>
              <p>The landlords register their properties on the platform. They collect the KYC of their tenants
                and link them to the properties. We allow the landlords to create as many properties as possible.</p>

              <h5>2. Register Defaulting Tenants and Verify Prospective Tenants.</h5>
              <p>We create defaults register where landlords can report their defaulting tenants.
The landlords can also verify the identity, credit and tenancy history of tenants. We give
insights into their characteristics and characters.</p>

              <h5>3. Tenants Management. </h5>
              <p>Instead of generating manual receipts, you can issue rent receipts, utility bills, send payment
              reminders and notifications to your tenants.</p>
            </div>


            
          </a>

          <a href="https://hrmaneja.com/" target="_blank" rel="noreferrer" >
            <div
              className="animate_top sg oi pi zq ml il am cn _m"
              style={{ cursor: "pointer" }}
            >
              <img
                width="300px"
                // height="50px"
                src="images/tenant.jpg"
                alt="Icon"
              />
              <h2 className="ek zj kk wm nb _b">For Tenants</h2>
              <h5>1. Credit Score Building</h5>
              <p>Build your credit score through your rental and utility bill payments to your landlords across
              different apartments.</p>

              <h5>2. Search for Properties</h5>
              <p>We offer Nigerian property seekers an easy way to find details of property like homes,
              houses, lands, shops, office spaces and other commercial properties.</p>

            </div>


            
          </a>
          {/* <a href="https://requisit-waitlist.vercel.app/" target="_blank" rel="noreferrer">
            {" "}
            <div
              className="animate_top sg oi pi zq ml il am cn _m"
              style={{ cursor: "pointer" }}
            >
              <div
                style={{
                  borderRadius: "15px",
                  width: "200px",
                  height: "200px",
                  overflow: "hidden",
                }}
              >
                <img
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  src="images/Requisite logo-06.png"
                  alt="Icon"
                />
              </div>

              <h4 className="ek zj kk wm nb _b">Requisite</h4>
              <p>
                A Powerful solution designed to streamline and automate
                requisition processes for organizations of all sizes.
              </p>
            </div>
          </a>

          <div
            className="animate_top sg oi pi zq ml il am cn _m"
            style={{ cursor: "pointer" }}
          >
            <div
              style={{
                borderRadius: "15px",
                width: "200px",
                height: "200px",
                overflow: "hidden",
                backgroundColor: "#821BF0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "60px", height: "60px", objectFit: "cover" }}
                src="images/receipt.png"
                alt="Icon"
              />
            </div>
            <h4 className="ek zj kk wm nb _b">Payroll Management</h4>
            <p>
              We assist you in managing your payroll with our automated system.
            </p>
          </div>
          <div
            className="animate_top sg oi pi zq ml il am cn _m"
            style={{ cursor: "pointer" }}
          >
            <div
              style={{
                borderRadius: "15px",
                width: "200px",
                height: "200px",
                overflow: "hidden",
                backgroundColor: "#F43661",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "60px", height: "60px", objectFit: "cover" }}
                src="images/calendar.png"
                alt="Icon"
              />
            </div>
            <h4 className="ek zj kk wm nb _b">Expense Management</h4>
            <p>Have the ultimate visibility of expenses in your company.</p>
          </div>
          <div
            className="animate_top sg oi pi zq ml il am cn _m"
            style={{ cursor: "pointer" }}
          >
            <div
              style={{
                borderRadius: "15px",
                width: "200px",
                height: "200px",
                overflow: "hidden",
                backgroundColor: "#F5C824",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "60px", height: "60px", objectFit: "cover" }}
                src="images/heart.png"
                alt="Icon"
              />
            </div>
            <h4 className="ek zj kk wm nb _b">
              Health Management Information System(S) (HMIS)
            </h4>
            <p>
              Collect, process, store and use health-related information to
              offer solutions for the health condition of your patients.
            </p>
          </div>
          <div
            className="animate_top sg oi pi zq ml il am cn _m"
            style={{ cursor: "pointer" }}
          >
            <div
              style={{
                borderRadius: "15px",
                width: "200px",
                height: "200px",
                overflow: "hidden",
                backgroundColor: "#16C1A1",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "60px", height: "60px", objectFit: "cover" }}
                src="images/smartphone.png"
                alt="Icon"
              />
            </div>
            <h4 className="ek zj kk wm nb _b">Task Management</h4>
            <p>
              Track your timeline, schedule some tasks. Rule your task and
              manage your competency in one place.
            </p>
          </div>
          <div
            className="animate_top sg oi pi zq ml il am cn _m"
            style={{ cursor: "pointer" }}
          >
            <div
              style={{
                borderRadius: "15px",
                width: "200px",
                height: "200px",
                overflow: "hidden",
                backgroundColor: "#821BF0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "60px", height: "60px", objectFit: "cover" }}
                src="images/inventory.png"
                alt="Icon"
              />
            </div>
            <h4 className="ek zj kk wm nb _b">Inventory Management</h4>
            <p>
              Keep track of your inventory, equipments and get notified when you
              are out of stock.
            </p>
          </div> */}

          {/* Repeat the above structure for other service items */}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
