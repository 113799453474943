/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const About = () => {
  return (
    <section
      className="ji gp uq 2xl:ud-py-35 pg"
      style={{ marginTop: 20 }}
    >
      
      <div className="bb ze ki xn 2xl:ud-px-0">

        <div
          className="tc wf gg qq"
          style={{ marginTop: 20, marginBottom: 100 }}
        >
          {/* About Content */}
          <div className="animate_right jn/2">
            <h4  style={{ color: "#2e43e7" }}  className="ek yj mk gb">Our Mission</h4>
            <div style={{ marginBottom: "15px" }}>
              <h2 className="fk vj zp pr kk wm qb">
               Honesty, Integrity, and Transparency
              </h2>
              <p className="uo">
              At E-Tracka, we are committed to honesty integrity, and transparency.
              Our mission is to ensure that you find your dream home with confidence and ease.
              </p>
            </div>

            <a
              style={{
                backgroundColor: "white",
                color: "#2e43e7",
                borderColor: "#2e43e7",
                borderStyle: "solid",
                borderWidth: 1,
                marginTop: 20,
              }}
               href="https://etracker-software-frontend.vercel.app"
              className=" jk gh gi hi  ml il vc _d _l"
            >
              Get Started
            </a>
          </div>
          {/* About Images */}
          <div className="animate_left xc gn gg jn/2 i" >
            <div>
              {/* <img
                src="images/shape-05.svg"
                alt="Shape"
                className="h -ud-left-5 x"
              /> */}
              <img src="images/about-13.png" alt="About" className="ib" />
              <img src="images/about-15.png" alt="About" />
            </div>
            <div>
              <img src="images/shape-20.svg" alt="Shape" />
              <img src="images/about-14.png" alt="About" className="ob gb" />
              <img src="images/shape-21.svg" alt="Shape" />
            </div>
          </div>
        </div>
        <div className="tc wf gg qq" >
          {/* About Images */}
          <div className="animate_left xc gn gg jn/2 i">
            <div>
              {/* <img
                src="images/shape-05.svg"
                alt="Shape"
                className="h -ud-left-5 x"
              /> */}
              <img src="images/about-7.png" alt="About" className="ib" />
              <img src="images/about-9.png" alt="About" />
            </div>
            <div>
              <img src="images/shape-20.svg" alt="Shape" />
              <img src="images/about-8.png" alt="About" className="ob gb" />
              <img src="images/shape-21.svg" alt="Shape"  />
            </div>
          </div>

          {/* About Content */}
          <div className="animate_right jn/2" >
            <h4 style={{ color: "#2e43e7" }} className="ek yj mk gb">Our Vision</h4>
            <h2 className="fk vj zp pr kk wm qb">
              To be the most trusted real estate platform
            </h2>
            <p className="uo">
            Our vision is to be the leading real estate platform providing reliable data and seamless property management services.
            </p>

            <a
              style={{
                backgroundColor: "white",
                color: "#2e43e7",
                borderColor: "#2e43e7",
                borderStyle: "solid",
                borderWidth: 1,
                marginTop: 20,
              }}
               href="https://etracker-software-frontend.vercel.app"
              className=" jk gh gi hi  ml il vc _d _l"
            >
              Get Started
            </a>
          </div>
        </div>

        {/* <div className="tc wf gg qq">
          <div className="animate_left xc gn gg jn/2 i">
            <div>
              <img
                src="images/shape-05.svg"
                alt="Shape"
                className="h -ud-left-5 x"
              />
              <img src="images/about-7.png" alt="About" className="ib" />
              <img src="images/about-9.png" alt="About" />
            </div>
            <div>
              <img src="images/shape-06.svg" alt="Shape" />
              <img src="images/about-8.png" alt="About" className="ob gb" />
              <img src="images/shape-07.svg" alt="Shape" className="bb" />
            </div>
          </div>

          <div className="animate_right jn/2">
            <h4  style={{ color: "#2e43e7" }}  className="ek yj mk gb">Why Choose Us</h4>
            <h2 className="fk vj zp pr kk wm qb">The Essence.</h2>
            <p className="uo">
              In the world that technology is obviously the future, we present
              you an automation system that speedily streamlines work processes
              and effectively manage your company time, data and other
              resources.
            </p>

            <a
              style={{
                backgroundColor: "white",
                color: "#2e43e7",
                borderColor: "#2e43e7",
                borderStyle: "solid",
                borderWidth: 1,
                marginTop: 20,
              }}
              href="/contact"
              className="ek jk lk gh gi hi rg ml il vc _d _l"
            >
              Get Started
            </a>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default About;
