/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const Clients = () => {
    const sectionTitle = 'Backed and supported by';
    const sectionTitleText =
      'We are backed and supported by some of the most innovative and creative companies in the industry.';
  
  return (
    <section className="pj vp mr">
      {/* Section Title Start */}
      <div
        data-={{ sectionTitle: 'Backed and supported by', sectionTitleText: '' }}
      >
        <div className="animate_top bb ze rj ki xn vq">
          <h2
            dangerouslySetInnerHTML={{ __html: sectionTitle }}
            className="fk vj pr kk wm on/5 gq/2 bb _b"
          ></h2>
          <p className="bb on/5 wo/5 hq" dangerouslySetInnerHTML={{ __html: sectionTitleText }}></p>
        </div>
      </div>
      {/* Section Title End */}

      <div className="bb ze ah ch pm hj xp ki xn 2xl:ud-px-49 bc">
        <div className="wc rf qn zf cp kq xf wf">
          <a href="#" className="rc animate_top">
            <img className="th wl ml il zl om" src="images/brand-light-01.svg" alt="Clients" />
            <img className="xc sk ml il zl nm" src="images/brand-dark-01.svg" alt="Clients" />
          </a>
          <a href="#" className="rc animate_top">
            <img className="tk ml il zl om" src="images/brand-light-02.svg" alt="Clients" />
            <img className="xc sk ml il zl nm" src="images/brand-dark-02.svg" alt="Clients" />
          </a>
          <a href="#" className="rc animate_top">
            <img className="tk ml il zl om" src="images/brand-light-03.svg" alt="Clients" />
            <img className="xc sk ml il zl nm" src="images/brand-dark-03.svg" alt="Clients" />
          </a>
          <a href="#" className="rc animate_top">
            <img className="tk ml il zl om" src="images/brand-light-04.svg" alt="Clients" />
            <img className="xc sk ml il zl nm" src="images/brand-dark-04.svg" alt="Clients" />
          </a>
          <a href="#" className="rc animate_top">
            <img className="tk ml il zl om" src="images/brand-light-05.svg" alt="Clients" />
            <img className="xc sk ml il zl nm" src="images/brand-dark-05.svg" alt="Clients" />
          </a>
          <a href="#" className="rc animate_top">
            <img className="tk ml il zl om" src="images/brand-light-06.svg" alt="Clients" />
            <img className="xc sk ml il zl nm" src="images/brand-dark-06.svg" alt="Clients" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Clients;
